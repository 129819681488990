<template>
    <div>
        <b-row>
            <b-col cols="3">
                <zw-input-group v-model="inputVal.time"
                                name="time"
                                :label-prefix="labelPrefix"
                                validate="required"
                ></zw-input-group>
            </b-col>
            <b-col cols="3">
                <zw-select-group v-model="inputVal.time_type"
                                 :options="timeTypeOptions"
                                 name="time_type"
                                 :label-prefix="labelPrefix"
                                 validate="required"
                ></zw-select-group>
            </b-col>
            <b-col cols="3">
                <zw-select-group v-model="inputVal.direction"
                                 :options="timeDirectionOptions"
                                 name="direction"
                                 :label-prefix="labelPrefix"
                                 validate="required"
                ></zw-select-group>
            </b-col>
            <b-col cols="3">
                <zw-select-group v-model="inputVal.attribute"
                                 :options="getTimeAttributes() | optionsVV"
                                 name="attribute"
                                 :label-prefix="labelPrefix"
                                 validate="required"
                ></zw-select-group>
            </b-col>
        </b-row>
        <hr>
        <h4>{{ $t('settings.workflow.label.position_detection') }}</h4>

        <condition v-for="(rule,condition_index) in inputVal.conditions"
                   :key="condition_index"
                   :index="condition_index"
                   v-model="inputVal.conditions[condition_index]"
                   :attributes="[{value:'category',text:'category'}]"
                   :conditions="getConditions()"
                   :time-types="getTimeTypes()"
                   :remove="()=>{remove(condition_index)}"
                   :attributeModel="'offering'"
        ></condition>

        <b-button variant="success"
                  @click="addCondition()"
                  :title="$t('common.title.add')"
                  class="mr-2"
        >
            <font-awesome-icon icon="plus"/>
            {{ $t('settings.workflow.button.add_condition') }}
        </b-button>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Condition from "@/components/Condition";

export default {
    name: 'CountdownOptions',
    components: {Condition},
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {
        ...mapGetters('Workflow', ['getTimeTypes', 'getTimeAttributes', 'getTimeDirections', 'getConditions']),
        addCondition() {
            let conditions = this.inputVal.conditions || []
            conditions.push({})
            this.$set(this.inputVal, 'conditions', conditions)
        },
        remove(condition_index) {
            let conditions = this.inputVal.conditions.filter((data, i) => {
                return i != condition_index
            });
            this.$set(this.inputVal, 'conditions', conditions)

        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        timeTypeOptions() {
            return this.getTimeTypes().map(val => {
                return {value: val, text: this.$t('settings.workflow.time_type.' + val)}
            })
        },
        timeDirectionOptions() {
            return this.getTimeDirections().map(val => {
                return {value: val, text: this.$t('settings.workflow.time_direction.' + val)}
            })
        },
    },
}
</script>